import { Injectable } from '@angular/core';
import { get, set } from './storage.service';
export const FIRST_NAME_KEY = 'user-first-name';
export const SECOND_NAME_KEY = 'user-second-name';
export const EMAIL_KEY = 'user-email';
export const NOTIFICATIONS_KEY = 'has-notification';
export const NOTIFICATIONS_OBJECT_KEY = 'notifications-object'
@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  constructor() { }

  public static logFormData(formData: FormData) {
    this.printConsoleBreaker('Form Data Start');
    formData.forEach((value, key) => {
      console.log(key + ', ' + value);
    });
    this.printConsoleBreaker('Form Data End');
  }

  public static printConsoleBreaker(stringToPrint: string) {
    console.log('========================================================== ',
        stringToPrint,
        ' ==========================================================');
  }

  public static shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  public static async checkIfNewNotification(notificationsFromServer: any){
    let savedNotifications = await get(NOTIFICATIONS_OBJECT_KEY);
    let hasNotification = false;
          //if we do have saved notifications from before
          if(savedNotifications != null){
            //if they are equal lengths, check if they are the same notifications too
            if(notificationsFromServer.length == savedNotifications.length){
              let isSame = true;
              for (let index = 0; index < notificationsFromServer.length; index++) {
                if(notificationsFromServer[index].id != savedNotifications[index].id){
                  isSame = false;
                }
              }
              if(isSame){
                console.log("they are the same");
                set(NOTIFICATIONS_KEY, false);
                hasNotification = false;
              }else{
                console.log("they are different");
                set(NOTIFICATIONS_KEY, true);
                hasNotification = true;
              }
            }
            //if theyre not equal lengths, they are different, therefore something new has happened
            else{
              set(NOTIFICATIONS_KEY, true);
              hasNotification = true;
            }
          }else{
            set(NOTIFICATIONS_OBJECT_KEY, notificationsFromServer);
            if(notificationsFromServer.length > 0){
              set(NOTIFICATIONS_KEY, true);
              hasNotification = true;
            }else{
              set(NOTIFICATIONS_KEY, false);
              hasNotification = false;
            }
          }

          return hasNotification;
  }}
