import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export const LNG_KEY = 'SELECTED_LANGUAGE';
import { get, set, remove } from 'src/app/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
selected = '';
  constructor(private translate: TranslateService) { }

  async setInitialAppLanguage(){
    console.log("set language");
    //let language = this.translate.getBrowserLang();
    //this.translate.setDefaultLang(language);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    const lang = await get(LNG_KEY);
    

    if(lang !== "null" && lang !== null && lang != undefined) {
        console.log("setting language: ", lang);
        this.setLanguage(lang);
        this.selected = lang;
    }
  }

  getLanguages(){
    return [
      {text: 'English', value: 'en', img: '../assets/imgs/ic_tick_red_1.png'},
      {text: 'German', value: 'de', img: '../assets/imgs/ic_tick_red_1.png'},
    ];
  }

  async setLanguage(lng){
    this.translate.use(lng);
    this.selected = lng;
    await set(LNG_KEY, lng);
  }
}
