import { Component, HostListener } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { LanguageService } from './services/language.service';
import { ConnectionStatus, NetworkService } from './services/network.service';
import { OfflineManagerService } from './services/offline-manager.service';
import { get } from 'src/app/services/storage.service';
import { FIRST_NAME_KEY, SECOND_NAME_KEY, EMAIL_KEY } from 'src/app/services/global-variables.service';

const aboutImage = "../assets/imgs/icon-about.png";
const logoutImage = "../assets/imgs/icon-logout.png";
const studyImage = "../assets/imgs/icon-study-white.png";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent {
    public name: string;
    public email: string;
    public showCustomSideMenu: boolean = false;
    public pageList = [
        {
            iconName: studyImage, displayText: 'Study', onclick: "openPage('/study')", url: '/study'
        },
        {
            iconName: aboutImage, displayText: 'About', onclick: "openPage('/about')", url: '/about'
        },
        {
            iconName: logoutImage, displayText: 'Logout', onclick: "logout()", url: '/log-in'
        },
    ];

    constructor(
        private platform: Platform,
        private menuController: MenuController,
        private LanguageService: LanguageService,
        private offlineManager: OfflineManagerService,
        private networkService: NetworkService,
        private screenOrientation: ScreenOrientation
    ) {
        this.initializeApp();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.checkWindowWidth();
    }

    checkWindowWidth() {
        const width = window.innerWidth;
        this.showCustomSideMenu = width < 769;
    }

    initializeApp() {
        this.platform.ready().then(async () => {
            if (Capacitor.platform != 'web') {
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
            }
            this.LanguageService.setInitialAppLanguage();
            this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
                if (status == ConnectionStatus.Online) {
                    this.offlineManager.checkForEvents().subscribe();
                }
            });
        });
        this.checkWindowWidth();
    }

    async closeMenu() {
        if (await this.menuController.isOpen()) {
            await this.menuController.close();
            await this.menuController.enable(false);
        }
    }

    async ionMenuOpened() {
        console.log("called");
        const firstName = await get(FIRST_NAME_KEY);
        const secondName = await get(SECOND_NAME_KEY);
        this.email = await get(EMAIL_KEY);
        this.name = firstName + ' ' + secondName;
    }
}
