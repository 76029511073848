import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuOption } from './models/side-menu-option';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'custom-side-menu',
    templateUrl: './custom-side-menu.component.html',
    styleUrls: ['./custom-side-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomSideMenuComponent {
    @Input() name: string;
    @Input() email: string;
    optionHeight = 50;
    paddingLeft = 16;
    @Input() menuList: Array<SideMenuOption>;

    constructor(private router: Router, private navCtrl: NavController, private authService: AuthenticationService) {

    }

    toggle(item) {
        item.expanded = !item.expanded;
    }

    async logout() {
        await this.authService.logout();
        this.router.navigateByUrl('log-in', { replaceUrl: true });
    }

    openPage(page: string) {
        //if selecting the route for the current page is the same as the current page, opening
        //a new page won't show the back button to navigate back to route. calling this function
        //instead will show the back button.
        if (page == '/study' || page == '/my-subscription') {
            if (this.router.url == '/start' || this.router.url == page) {
                this.router.navigateByUrl(page, { replaceUrl: true });
            } else {
                this.navCtrl.navigateRoot(page);
            }
        } else if (this.router.url == page) {
            console.log('equal');
            this.router.navigateByUrl(page, { replaceUrl: true });
        } else {
            console.log('not equal');
            this.navCtrl.navigateRoot(page);
        }
    }
}
