import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ToastController } from "@ionic/angular";
import { BehaviorSubject, EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, delay, filter, finalize, map, retryWhen, switchMap, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../services/authentication.service";
import { ConnectionStatus, NetworkService } from "../services/network.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;
   constructor(private authService: AuthenticationService, private toastCtrl: ToastController, private router: Router, private networkService: NetworkService){}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        //do we need to intercept?
        if(this.isInBlockedList(request.url)){
            if( request.url == environment.api_url + 'Identity/RefreshToken'){
                return next.handle(this.addToken(request)).pipe(
                    catchError(err =>{
                        if(err instanceof HttpErrorResponse){
                            switch(err.status){
                                case 400:
                                    return this.handle401Error(request, next);
                                default:
                                    return this.handleLogoutError(err);
                            }
                        } else {
                            return throwError(err);
                        }
                    })
                )
            } else {
                //YES - return the req
                return next.handle(request);
            }
            
        } else {
            //NO - add the JWT
                  console.log("add jwt");
                return next.handle(this.addToken(request)).pipe(
                    catchError(err =>{
                        if(err instanceof HttpErrorResponse){
                            switch(err.status){
                                case 401:
                                    //401 - start the token refresh
                                    return this.handle401Error(request, next);
                                default:
                                    return throwError(err);    
                            }
                        } else {
                            return throwError(err);
                        }
                    }),
                    retryWhen(err => {
                        let retries = 1;
                        return err.pipe(
                            delay(1000),
                            tap(() =>{
                                console.log("retries: ", retries);
                            }),
                            map(error => {
                                if(retries++ == 3){
                                    throw error;
                                }
                                return error;
                            })
                        )
                    }),
                    catchError(err => {
                        console.log("error: ", err);
                        return throwError(err);
                    }),
                    finalize(() => {
                        console.log("finally");
                    })
                )
               
        }
        return next.handle(request);
    }

    private isInBlockedList(url: string): Boolean{
        if(url == environment.api_url + 'Identity/Authenticate' || url == environment.api_url + 'Identity/RefreshToken' || url == environment.api_url + 'App/Quote'){
            return true;
        } else {
            return false;
        }
    }

    private addToken(req: HttpRequest<any>){
        if(this.authService.currentAccessToken){
            return req.clone({
                headers: new HttpHeaders({
                    Authorization: 'Bearer ' + this.authService.currentAccessToken,
                    'Content-Type':  'application/json'
                    
                })
            })
        } else {
            return req;
        }
    }

    private async handleLogoutError(err){
        const toast = await this.toastCtrl.create({
            message: 'Logged out due to authentication mismatch',
            duration: 2000
        });
        toast.present();
        await this.authService.logout();
        this.router.navigateByUrl('log-in', {replaceUrl: true});
        return of(null);
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any>{
        if(!this.isRefreshingToken){
            //REFRESH
            console.log("Refreshing token");
            this.tokenSubject.next(null);
            this.isRefreshingToken = true;
            this.authService.currentAccessToken = null;

            return this.authService.getNewAccessToken().pipe(
                switchMap((token: any) => {
                   if(token){
                       console.log("data from refresh token: ", token);
                    const accessToken = token.jwtToken;
                    const refreshToken = token.refreshToken;
                    return this.authService.storeAccessToken(accessToken, refreshToken).pipe(
                        switchMap(_ => {
                            this.tokenSubject.next(accessToken)
                            return next.handle(this.addToken(request));
                        })
                    )
                   } else {
                       return of(null);
                   }
                }), finalize(() => {
                    this.isRefreshingToken = false;
                })
            )
        } else {
            console.log("Not refreshing");
            return this.tokenSubject.pipe(
                filter(token => token !== null),
                take(1),
                switchMap(_ => {
                    return next.handle(this.addToken(request));
                }), finalize(() => {
                    this.isRefreshingToken = false;
                })
            )
        }
    }
}