import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import {tap, switchMap, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CHECKOUT_API_URL } from 'src/app/services/app-purchases.service';
import { FIRST_NAME_KEY, SECOND_NAME_KEY, EMAIL_KEY } from './global-variables.service';
import { get, set, removeAll } from 'src/app/services/storage.service';
import jwt_decode from "jwt-decode";
const REFRESH_TOKEN_KEY = 'my-refresh-token';
const ACCESS_TOKEN_KEY = 'my-jwt-token';
const DEVICE_ID_KEY = 'device-id';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private static _jwtToken: string;
  private static _refreshToken: string;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  currentAccessToken = null;
  currentRefreshToken = null;
  url = environment.api_url;

  constructor(private http: HttpClient) {
    this.loadToken();
   }

   static get jwtToken(): string {
    return this._jwtToken;
  }

  static set jwtToken(value: string) {
    this._jwtToken = value;
  }

  static get refreshToken(): string {
    return this._refreshToken;
  }

  static set refreshToken(value: string) {
    this._refreshToken = value;
  }

   async loadToken(){
    console.log('Load Token');
    const token = await get(ACCESS_TOKEN_KEY);
    if(token){
      console.log('set token: ', token);
      this.currentAccessToken = token;
      AuthenticationService.jwtToken = token;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
   }

   login(credentials: {email:string, password:string}): Observable<any>{
    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/json');
     return this.http.post(this.url +'Identity/Authenticate', credentials).pipe(
       switchMap((tokens: {jwtToken, refreshToken}) => {
         this.currentAccessToken = tokens.jwtToken;
         this.currentRefreshToken = tokens.refreshToken;
         const decoded: any = jwt_decode(this.currentAccessToken);
         console.log(decoded);

        //  const date = new Date().getTime() / 1000 | 0; // Unix/Epoch Time
        // console.log('date', date);
        // console.log('decoded.exp - 10', decoded.exp - 10);
        // console.log('date.getSeconds() > decoded.exp -10: ', date > decoded.exp - 10);
         const fullName: string = decoded.UserName;
         let splitName = fullName.split(" ", 2); 
         var lastName: string;
         if(splitName.length != 2){
            lastName = splitName[1];
         } else {
           lastName = " ";
         }
         const storeFirstName = set(FIRST_NAME_KEY, splitName[0]);
         const storeSecondName = set(SECOND_NAME_KEY, lastName);
         const storeEmail = set(EMAIL_KEY, decoded.Email);
         AuthenticationService.jwtToken = tokens.jwtToken;
         AuthenticationService.refreshToken = tokens.refreshToken;
         const storeJwt = set(ACCESS_TOKEN_KEY, tokens.jwtToken);
         const storeRefresh = set(REFRESH_TOKEN_KEY, tokens.refreshToken);
         return from(Promise.all([storeJwt, storeRefresh, storeEmail, storeFirstName, storeSecondName]));
       }),
       tap(_ => {
         this.isAuthenticated.next(true);
       }),timeout(15000)
     )

   }

   async logout(){
    this.isAuthenticated.next(false);
    const deviceId = await get(DEVICE_ID_KEY);
      if(deviceId != null && deviceId != undefined && deviceId != ''){
        this.deleteDeviceId(deviceId).subscribe((res:any) => {
          console.log("post delete device id res: ",res);
      });
      }
    const removeAllKeys = removeAll();
     return from(Promise.all([removeAllKeys]));
   }


   getNewAccessToken(){
     const refreshToken = from(get(REFRESH_TOKEN_KEY));
     
     
     console.log("Current Access Token: ", AuthenticationService.jwtToken);
     return refreshToken.pipe(
       switchMap(token => {
         if(token){
          const bodyElement: object = {
            JwtToken: AuthenticationService.jwtToken,
            RefreshToken: token
        };
        console.log(bodyElement);
           return this.http.post(this.url + 'Identity/RefreshToken', bodyElement);
         } else {
           return of(null);
         }
       })
     )
   }

   storeAccessToken(accessToken, refreshToken){
     this.currentAccessToken = accessToken;
     AuthenticationService.jwtToken = accessToken;
     AuthenticationService.refreshToken = refreshToken;
     const storeRefresh = set(REFRESH_TOKEN_KEY,refreshToken);
     return from(set(ACCESS_TOKEN_KEY,accessToken));
   }

   
   getMockData(): Observable<object>{
     return this.http.get(this.url + 'App/Card');
   }

   getMockTopics(): Observable<object>{
    return this.http.get(this.url + 'App/Topics').pipe(
      timeout(15000)
    );
  }

  getQuote(): Observable<object>{
    return this.http.get(this.url + 'App/Quote?language=en');
  }

  getNotifications(): Observable<object>{
    return this.http.get(this.url + 'App/Notifications');
  }

  getPromoCode(code: string): Observable<object>{
    return this.http.get(this.url + 'App/PromoCodes/' + code);
  }

  getCheckSubscriptions(): Observable<object>{
    return this.http.get(this.url + 'App/CheckSubscriptions');
  }


  postCard(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/Card', bodyElement).pipe(
      timeout(15000)
    );
  }

  postResetCardProgress(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/ResetCardProgress', bodyElement);
  }

  postProgress(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/UserProgress', bodyElement).pipe(
      timeout(15000)
    );
  }

  postQuery(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/Query', bodyElement);
  }

  postRegister(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/Register', bodyElement);
  }

  postVerifyAndroidReceipt(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/VerifyAndroidReceipt', bodyElement);
  }

  postVerifyiOSReceipt(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/VerifyiOSReceipt', bodyElement);
  }

  postVerifyLatestiOSReceipt(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/VerifyLatestiOSReceipt', bodyElement);
  }

  postSponsorClick(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/SponsorClick', bodyElement);
  }

  postResetPassword(bodyElement: object): Observable<object>{
    return this.http.post(this.url + 'App/ResetPasswordRequest', bodyElement);
  }


  putSettings(bodyElement: object): Observable<object>{
    return this.http.put(this.url + 'App/Settings', bodyElement);
  }

  putPromoCode(code: string): Observable<object>{
    return this.http.put(this.url + 'App/PromoCodes/' + code, null);
  }

  postDeviceId(deviceId: string): Observable<object>{
    return this.http.post(this.url + 'App/Device/' + deviceId, null);
  }

  deleteDeviceId(deviceId: string): Observable<object>{
    return this.http.delete(this.url + 'App/Device/' + deviceId);
  }

  deleteUserProgress(): Observable<object>{
    return this.http.delete(this.url + 'App/DeleteUserProgress');
  }

  

  

}
