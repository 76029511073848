// ANGULAR
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// IONIC
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRate } from '@ionic-native/app-rate/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Device } from '@ionic-native/device/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { LaunchReview } from '@ionic-native/launch-review/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

// MODULES
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { QuillModule } from 'ngx-quill'

import { AppComponent } from './app.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    ComponentsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        readOnly: true
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AppRate,
    Device,
    Dialogs,
    InAppPurchase2,
    LaunchReview,
    NativeStorage,
    Network,
    ScreenOrientation,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
