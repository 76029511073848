import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { IntroGuard } from './guards/intro.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'quote',
    pathMatch: 'full'
  },
  {
    path: 'about',
    loadChildren: () => import('./Pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('./Pages/cards/cards.module').then(m => m.CardsPageModule)
  },
  {
    path: 'cards-mobile',
    loadChildren: () => import('./Pages/cards-mobile/cards-mobile.module').then(m => m.CardsMobilePageModule)
  },
  {
    path: 'cards-explain',
    loadChildren: () => import('./Pages/cards-explain/cards-explain.module').then(m => m.CardsExplainPageModule)
  },
  {
    path: 'card-query',
    loadChildren: () => import('./Pages/card-query/card-query.module').then(m => m.CardQueryPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./Pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'known-levels',
    loadChildren: () => import('./Pages/known-levels/known-levels.module').then(m => m.KnownLevelsPageModule)
  },
  {
    path: 'language-popover',
    loadChildren: () => import('./Pages/language-popover/language-popover.module').then(m => m.LanguagePopoverPageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'log-in',
    loadChildren: () => import('./Pages/log-in/log-in.module').then(m => m.LogInPageModule),
    canLoad: [IntroGuard]
  },
  {
    path: 'my-subscription',
    loadChildren: () => import('./Pages/my-subscription/my-subscription.module').then(m => m.MySubscriptionPageModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./Pages/notifications/notifications.module').then(m => m.NotificationsPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'quote',
    loadChildren: () => import('./Pages/quote/quote.module').then(m => m.QuotePageModule)
  },
  {
    path: 'redeem-code',
    loadChildren: () => import('./Pages/redeem-code/redeem-code.module').then(m => m.RedeemCodePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./Pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./Pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'study',
    loadChildren: () => import('./Pages/study/study.module').then(m => m.StudyPageModule),
  },
  {
    path: 'study-levels',
    loadChildren: () => import('./Pages/study-levels/study-levels.module').then(m => m.StudyLevelsPageModule)
  },
  {
    path: 'subscription-choose',
    loadChildren: () => import('./Pages/subscription-choose/subscription-choose.module').then(m => m.SubscriptionChoosePageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./Pages/terms/terms.module').then(m => m.TermsPageModule)
  },
  {
    path: 'topics',
    loadChildren: () => import('./Pages/topics/topics.module').then(m => m.TopicsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
