import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { LoadingService } from '../services/loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router, private loadingService: LoadingService){}

  canLoad(): Observable<boolean>{
    return this.authService.isAuthenticated.pipe(
      filter( val => val !== null),
      take(1),
      map(isAuthenticated => {
        console.log('GUARD: ', isAuthenticated)
        if (isAuthenticated){
          return true;
        } else {
          this.loadingService.dismissLoadingIcon();
          this.authService.logout();
          this.router.navigateByUrl('log-in', {replaceUrl: true});
          return false;
        }
      })
    )
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authService.isAuthenticated.pipe(
      filter( val => val !== null),
      take(1),
      map(async isAuthenticated => {
        console.log('GUARD: ', isAuthenticated)
        if (isAuthenticated){
          return true;
        } else {
          await this.loadingService.dismissLoadingIcon();
          await this.authService.logout();
          this.router.navigateByUrl('log-in', {replaceUrl: true});
          return false;
        }
      })
    ).toPromise();
  }  
}
