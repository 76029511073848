import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading: boolean = false
  loadingIcon: any;
  constructor(private loadingController: LoadingController) { }

  async createLoadingIcon(){
    this.loadingIcon = await this.loadingController.create();
    this.isLoading = true
    await this.loadingIcon.present();
  }

  async dismissLoadingIcon(){
    this.isLoading = false
    await this.loadingIcon?.dismiss();
  }
}
